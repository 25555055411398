.top_div {
  border-top: 2px solid rgba(255, 255, 255, 0.1);
}

.container {
  color: white;
  margin: 0px 6rem;
  display: flex;
  justify-content: space-between;
}

.secondContainer {
  color: white;
  margin: 0px 6rem;
  display: flex;
  justify-content: space-between;
}

.heading {
  padding-bottom: 10px;
  margin: 8px;
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 600;
  border-bottom: 1px solid #fdb813;
}

.subheading {
  padding-top: 12px;
  padding-left: 12px;
  font-size: 20px;
  font-weight: 900;
  text-align: justify;
  color: rgba(255, 255, 255, 0.7);
}

.paragraph {
  padding-bottom: 12px;
  padding-left: 12px;
  font-size: 18px;
  line-height: 35px;
  text-align: justify;
  color: rgba(255, 255, 255, 0.7);
}

.left_container {
  width: 50%;
}

.right_container {
  width: 50%;
  text-align: center;
}

.right_container img {
  width: 90%;
  height: max-content;
  margin-top: 30px;
}

@media screen and (max-width: 900px) {
  .container {
    flex-direction: column;
    margin: 1rem;
  }

  .left_container,
  .right_container {
    width: 100%;
  }
}
