.footer {
  margin: 10px 6rem;
  margin-top: 100px;
}

.primary_footer {
  display: flex;
  justify-content: space-between;
}

.hr {
  opacity: 0.2;
  width: 30%;
  margin: 0;
  border: none;
  border-top: 1px solid white;
}

.p {
  color: rgba(255, 255, 255, 0.2);
}

.p a {
  color: rgba(255, 255, 255, 0.2);
  text-decoration: none;
}

.nav_items {
  display: flex;
  flex-wrap: wrap;
}

.nav_item {
  margin: 0px 10px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 25px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.nav_item:hover {
  transform: scale(1.2);
}

@media (max-width: 900px) {
  .footer {
    margin: 10px 20px;
  }
  .hr {
    width: 100%;
  }

  .nav {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 40px;
  }

  .p {
    font-size: 15px;
  }

  .nav_item {
    font-size: 20px;
  }
}
