@font-face {
  font-family: "Metropolis-regular";
  src: url("../../metropolis/Metropolis-Regular.otf") format("woff2");
}

.top_div {
  border-top: 2px solid rgba(255, 255, 255, 0.1);
}

.container {
  color: white;
  margin: 50px 6rem;
  display: flex;
  justify-content: space-between;
}

.heading {
  padding-bottom: 10px;
  margin: 8px;
  font-size: 2rem;
  font-weight: 600;
  border-bottom: 1px solid #fdb813;
}

.sub_heading {
  padding-left: 20px;
  font-size: 18px;
  margin-top: 25px;
  font-family: "metropolis-regular";
}

.form {
  display: flex;
  flex-direction: column;
}

.form_input {
  max-width: 90%;
  min-width: 20%;
  margin: 20px;
  padding: 10px;
  font-size: 1rem;
  border: none;
  outline: none;
  color: white;
  background-color: black;
  border-bottom: 2px solid gray;
  font-family: "Metropolis-regular";
}

.error_message {
  margin-left: 20px;
  margin-top: 0;
  font-size: 13px;
  color: rgb(184, 0, 0);
}

.validation_message {
  margin-left: 20px;
  margin-top: 0;
  font-size: 13px;
  color: green;
}

.submitBtn {
  width: 20%;
  padding: 0.7rem;
  margin: 20px;
  background-color: black;
  color: white;
  border: none;
  border: 2px solid #fdb813;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
}

.submitBtn:active {
  transform: scale(0.9);
}

.submitBtn:disabled {
  border-color: gray;
  color: gray;
}

.left_container {
  width: 50%;
}

.right_container {
  width: 50%;
  text-align: center;
  /* margin-top: 20px; */
}

.right_container img {
  width: 90%;
  height: 90%;
  object-fit: contain;
}

.submitted_msg {
  margin-left: 20px;
  margin-top: 0;
  font-size: 17px;
  color: green;
}

@media screen and (max-width: 900px) {
  .container {
    flex-direction: column;
    margin: 1rem;
  }

  .left_container,
  .right_container {
    width: 100%;
  }
}
