@font-face {
  font-family: "Metropolis-medium";
  src: url("../../metropolis/Metropolis-Medium.otf") format("woff2");
}

.nav {
  padding: 20px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: column; */
}

.brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.brandLogo {
  width: 55px;
  height: 60px;
}

.brandName {
  text-decoration: none;
  color: white;
  margin-left: 30px;
  font-size: 27px;
  font-family: "Metropolis-medium";
}

.nav_items {
  display: flex;
  height: 90px;
}

.nav_item {
  text-decoration: none;
  margin: 0px 20px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.nav_item:hover {
  transform: scale(1.1);
}

.admin_link {
  color: black;
  margin-right: 100px;
}

@media screen and (max-width: 900px) {
  .nav {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 40px;
  }

  .nav_items {
    margin-left: 75px;
    height: auto;
  }

  .nav_item {
    margin: 0 15px;
    font-size: 17px;
  }

  .admin_link {
    display: none;
  }
}
