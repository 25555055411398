.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px solid #fdb813;
  border-radius: 5px;
}

.login_heading {
  color: white;
  margin: 20px;
}

.login_input {
  margin: 10px;
  padding: 8px;
  font-size: 18px;
  background-color: black;
  border: none;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
}

.login_btn {
  width: 40%;
  margin: 10px;
  padding: 5px;
  font-size: 16px;
  color: black;
  background-color: #fdb813;
  border-radius: 5px;
  border: 2px solid #fdb813;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.login_btn:active {
  transform: scale(0.9);
}

.admin_container {
  margin: 0 100px;
  color: white;
}

.top_div {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin_heading {
  color: #fdb813;
}

.logOutBtn {
  background-color: black;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.logOutBtn:active {
  transform: scale(0.9);
}

.ToggleHeader {
  margin-top: 20px;
  margin-bottom: 50px;
  background-color: #fdb813;
  padding: 20px;
  border-radius: 10px;
}

.navBtn {
  text-decoration: none;
  color: black;
  border: none;
  margin: 10px;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  padding: 10px;
}

.activeBtn {
  border-bottom: 1px solid black;
}

.sub_container {
  margin-top: 20px;
}

.message_table {
  width: 100%;
  border: 2px solid #fdb813;
  border-collapse: collapse;
}

.message_table td {
  padding: 20px;
  max-width: 300px;
}

.message_table tr {
  border-bottom: 0.5px solid #fdb813;
}

.thead {
  border-bottom: 2px solid #fdb813;
}

.thead td {
  padding: 20px;
  font-weight: 700;
  font-size: 20px;
}

.replyBtn {
  text-decoration: none;
  color: white;
  padding: 5px;
  border: 1px solid white;
  border-radius: 5px;
}

.form {
  display: flex;
}

.dropdown {
  border: none;
  background-color: black;
  outline: none;
  color: white;
  border-bottom: 1px solid #fdb813;
  margin-right: 10px;
  font-size: 20px;
}

.dropdown option {
  font-size: 15px;
}

.skillInput {
  background-color: black;
  border: none;
  outline: none;
  color: white;
  border-bottom: 1px solid #fdb813;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 20px;
}

.addBtn {
  font-size: 16px;
  padding: 5px;
  width: 60px;
  cursor: pointer;
  background-color: #fdb813;
  color: black;
  border-radius: 5px;
  border: none;
  border: 1px solid #fdb813;
  transition: all 0.2s ease-in-out;
}

.addBtn:active {
  transform: scale(0.9);
}

.addBtn:disabled {
  color: gray;
  border-color: gray;
  background-color: black;
}

.breakdown {
  margin: 3% 0;
  border: none;
  border-bottom: 1px solid rgba(136, 135, 135, 0.3);
}

.designingSkillList {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.designingSkillList li {
  margin: 5px;
  border: 1px solid #fdb813;
  color: white;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.designingSkillList li p {
  margin: 0;
  margin-right: 10px;
}

.deleteIcon {
  cursor: pointer;
}

.deleteIcon:hover {
  color: #fdb813;
}
