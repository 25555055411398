@font-face {
  font-family: "Metropolis-Bold";
  src: url("../../metropolis/Metropolis-Bold.otf") format("woff2");
}

@keyframes move {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 30px;
  }
  100% {
    margin-top: 0;
  }
}

.container {
  color: white;
  display: flex;
  height: 100vh;
}

.left {
  width: 60%;
  padding-top: 150px;
  padding-left: 100px;
}

.intro {
  color: #fdb813;
  font-size: 20px;
  margin-bottom: 0;
}

.tagline {
  font-size: 35px;
  /* font-weight: 900; */
  font-family: "Metropolis-Bold";
  margin-bottom: 20px;
}

.right {
  padding-top: 3%;
  width: 40%;
  text-align: center;
}

.right img {
  width: 600px;
  height: 600px;
}

.description {
  margin-bottom: 60px;
}

.resumeBtn {
  font-size: 17px;
  color: black;
  background-color: #fdb813;
  border: 2px solid black;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 25px;
  margin-bottom: 20px;
  text-decoration: none;
  transition: all 0.2s ease-out;
  display: inline-block;
}

.aboutBtn {
  font-size: 17px;
  color: #fdb813;
  background-color: black;
  border: 2px solid #fdb813;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 25px;
  margin-bottom: 20px;
  text-decoration: none;
  transition: all 0.2s ease-out;
  display: inline-block;
}

.resumeBtn:active,
.aboutBtn:active {
  transform: scale(0.9);
}

.timeline_container {
  /* margin-top: 30px; */
  margin-left: -100px;
}

.hr {
  border: none;
}

.heading1 {
  padding-bottom: 10px;
  margin: 8px;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 200;
  border-bottom: 1px solid #fdb813;
}

.downArrow {
  padding-bottom: 10px;
  margin: 8px;
  margin-top: 10%;
  font-size: 40px;
  font-weight: 200;
  color: #fdb813;
}

.animate {
  animation: move 1.1s infinite ease-in-out;
}

.sub_heading {
  margin: 20px 30px;
  font-size: 18px;
  font-weight: 100;
}

.sub_heading span {
  color: #fdb813;
}

.designingSkillList {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.designingSkillList li {
  margin: 5px;
  border: 1px solid #fdb813;
  color: white;
  border-radius: 5px;
  padding: 10px;
}

.designingSkillList li:hover {
  background-color: #fdb813;
  color: black;
}

.project_btn {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.project_btn a {
  color: white;
  text-decoration: none;
}

.project_btn:hover {
  transform: scale(1.1);
}

.project_btn:active {
  transform: scale(1);
}

.resume_div {
  background-color: #fdb813;
  /* margin: 0 213px; */
  display: flex;
  padding: 20px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.resume_div p {
  font-size: 30px;
  font-weight: 800;
}

.loading_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .left {
    width: 90%;
    padding-left: 40px;
  }
  .right {
    width: 50%;
    margin-left: -220px;
    z-index: -1;
    overflow: hidden;
    opacity: 0.5;
  }

  .designingSkillList {
    margin-left: -1.5rem;
  }

  .designingSkillList li {
    font-size: 14px;
  }

  .heading1 {
    font-size: 1.4rem;
  }

  .timeline_container {
    margin-top: 0;
    margin-left: 0;
  }

  .resume_div {
    /* margin: 0 34px; */
    text-align: center;
  }
}
