.top_div {
  border-top: 2px solid rgba(255, 255, 255, 0.1);
}

.container {
  color: white;
  margin: 50px 6rem;
  display: flex;
  justify-content: space-between;
}

.heading {
  padding-bottom: 10px;
  margin: 8px;
  font-size: 2rem;
  font-weight: 600;
  border-bottom: 1px solid #fdb813;
  color: rgba(255, 255, 255, 0.9);
}

.paragraph {
  margin: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 25px;
  color: rgba(255, 255, 255, 0.8);
}

.left_container {
  width: 50%;
}

.right_container {
  width: 50%;
  text-align: center;
  /* margin-top: 20px; */
}

.right_container img {
  width: 90%;
  height: 90%;
  object-fit: contain;
}

@media screen and (max-width: 900px) {
  .container {
    flex-direction: column;
    margin: 1rem;
  }

  .left_container,
  .right_container {
    width: 100%;
  }
}
